import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { filter, map, mergeMap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MetaTagService {
	constructor(
		private title: Title,
		private metaService: Meta,
		@Inject(DOCUMENT) private doc
	) { }

	public setTags(metaTags: any[], copyTitle2DescriptionIfEmpty: boolean = true) {
		if (metaTags) {
			//Set given tags
			metaTags.forEach(siteTag => {
				if (siteTag.property == 'og:title') {
					this.metaService.updateTag({ property: siteTag.property, content: siteTag.content + ' | ' + environment?.siteParams?.legalName });
					this.title.setTitle(siteTag.content + ' | ' + environment?.siteParams?.legalName);
					// if ( copyTitle2DescriptionIfEmpty ) {
					//   this.metaService.updateTag( { property: 'og:description', content: siteTag.content } );
					// }
				} else
					this.metaService.updateTag({ property: siteTag.property, content: siteTag.content });

			});
			// Set url
			this.metaService.updateTag({ property: 'og:url', content: this.doc.URL });
			this.metaService.updateTag({ property: 'og:type', content: 'website' });
			this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
			// Twitter User
			if (environment.siteParams?.twitterUser != null)
				this.metaService.updateTag({ name: 'twitter:site', content: environment.siteParams?.twitterUser });

			// Canonical URL
			const link: HTMLLinkElement = this.doc.createElement('link');
			link.setAttribute('rel', 'canonical');
			this.doc.head.appendChild(link);
			link.setAttribute('href', this.doc.URL);

			//Set og:image if not provided
			// if (metaTags.filter(tag => tag.property == 'og:image')?.length == 0)
			// 	this.metaService.updateTag({ property: 'og:image', content: environment?.meta?.image });

			// //Set og:description if not provided
			// if (metaTags.filter(tag => tag.property == 'og:description')?.length == 0)
			// 	this.metaService.updateTag({ property: 'og:description', content: (copyTitle2DescriptionIfEmpty ? metaTags.filter(tag => tag.property == 'og:title')[0]?.content : environment?.meta?.description) });
		}
	}

	public getTags(router: Router, activatedRoute: ActivatedRoute): void {
		router.events.pipe(
			filter(e => e instanceof NavigationEnd),
			map(e => activatedRoute),
			map((route) => {
				while (route.firstChild)
					route = route.firstChild;
				return route;
			}),
			filter((route) => route.outlet === 'primary'),
			mergeMap((route) => route.data),
		).subscribe(data => {
			this.setTags(data['metaTags'], false);
		});
	}
}
